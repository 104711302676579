@import "variables-colors";

.ant-radio-button-wrapper:first-child {
  border-start-start-radius: 25px !important;
  border-end-start-radius: 25px !important;
}

.ant-radio-button-wrapper:last-child {
  border-start-end-radius: 25px !important;
  border-end-end-radius: 25px !important;
}

.ant-radio-button-checked {
  background-color: $selected;
}

.ant-radio-button-wrapper:first-child .ant-radio-button-checked {
  border-start-start-radius: 25px !important;
  border-end-start-radius: 25px !important;
}

.ant-radio-button-wrapper:last-child .ant-radio-button-checked {
  border-start-end-radius: 25px !important;
  border-end-end-radius: 25px !important;
}

.ant-radio-button-wrapper-checked {
  border-color: $colorPrimaryBorderHover !important;
}

.ant-radio-button-wrapper-checked:before {
  background-color: $colorPrimaryBorderHover !important;;
}

.ant-btn-link:hover:not(.ant-btn-link:disabled) {
  color: $colorPrimaryBorderHover !important;
}

// TODO: Move from global styles to fix font-size on the Pending Investments page.
.ant-dropdown-menu-submenu-title, .ant-dropdown-menu-item {
  font-size: 12px !important;
}

.ant-table-filter-dropdown-btns .ant-btn {
  font-size: 12px !important;
}
