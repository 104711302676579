@import "variables-colors";

.custom-menu.ant-menu {
  transition: width 2s cubic-bezier(0, 0, 0, 0) 2s;
}

.custom-menu {
  background: transparent;
  &.collapsed {
    .ant-menu-item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding-inline: 0;
    }
    .ant-menu-item > span {
      display: none;
    }

    .ant-menu-submenu-title {
      padding: 0;
    }
    .ant-menu-submenu-title > span {
      display: none !important;
    }

    .ant-menu-submenu-selected {
      background-color: $selected;
    }

    &.ant-menu-inline-collapsed {
      width: 100% !important;
    }
  }

  .ant-menu-item {
    margin: 0;
    padding-inline: 0;
    width: 100%;
  }

  .ant-menu-item.ant-menu-item-only-child {
    padding-left: 52px !important;
  }

  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: black;
  }

  .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    padding-inline: 0;
    width: 100%;
  }
}

.custom-menu {
  .ant-menu-item:hover {
    background-color: $selected !important;
  }

  .ant-menu-submenu-title:hover {
    background-color: $selected !important;
  }

  .ant-menu-item-only-child:before {
    content: "\2022" !important;
    position: relative;
    font-size: 15px;
    right: 19px;
    color: $navItemColor;
  }

  .ant-menu-submenu-arrow {
    color: $navItemColor !important;
  }

  .ant-menu-submenu-arrow:before,
  .ant-menu-submenu-arrow:after {
    height: 1px;
  }
}

//TABS
.ant-tabs-nav-list {
  height: 56px;
}

.ant-btn-text {
  padding: 5px;
}

.ant-popover-inner {
  padding: 0 !important;
}

.ant-input-number-handler {
  display: none !important;
}

.ant-table-cell-fix-left {
  z-index: 1 !important; //To make nav be over first table column
}

.ant-table-thead > tr > th {
  background-color: $lightPrimary !important;
}

.ant-table-ping-left
  .ant-table-tbody
  > tr:not(.ant-table-placeholder)
  > td:nth-child(1)::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: "";
  pointer-events: none;
  box-shadow: inset 10px 0 8px -8px rgba(5, 5, 5, 0.06);
}

.ant-menu-item,
.ant-menu-submenu-title {
  margin-bottom: 8px !important;
}

.ant-btn-primary {
  box-shadow: none;
}

.ant-btn-default:hover {
  color: black !important;
}

.ant-btn-primary:hover {
  background-color: $wildberry-color !important;
}

.ant-table .ant-table-header {
  border-radius: 0 !important;
}

.ant-table-footer {
  padding: 0 !important;
  border-radius: 0 !important;
}

.ant-table-row:last-child > td {
  border-bottom: none !important;
}

.ant-table-tbody > tr > td {
  padding: 5px 10px !important;
}

.ant-table-tbody .ant-table-measure-row > td {
  padding: 0 !important;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  height: 32px !important;
}

.editable-cell-value-wrap {
  font-size: 12px;
  text-align: end;
  border: 1px solid #d9d9d9;
  height: 32px !important;
  padding: 0 10px !important;
  position: relative;

  &.percentage:before {
    content: "%";
  }
  &.currency:before {
    content: "$";
  }

  &.percentage,
  &.currency {
    &:before {
      position: absolute;
      left: 10px;
      color: #d9d9d9;
    }
  }

  &.date {
    gap: 15px;
  }
  &.fullDate {
    gap: 5px;
  }

  &.date,
  &.fullDate {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &:after {
      content: url("../public/calendar.svg");
      color: #d9d9d9;
    }
  }
}

.indent-level-1 {
  padding-left: 19px !important;
}

.ant-picker-cell-disabled {
  color: rgba(0, 0, 0, 0.88);
}

.ant-picker-cell::before {
  display: none;
}
